const content = {
  footerDownloadAppText: "Download the App for Free",
  footerCompanyInfo:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  footerPromotionsText: "Promotions",
  footerUsefulText: "Useful Links",
  footerQuicText: "Quick Links",
  copyRightText: "Copyright © 2020 Studio Store, USA. All rights reserved.",
  callUs: "CALL US",
  loginNSignup: "Login / Sign up",
  dummyContactNumber: "+1 123 456 1234",
  aboutUs: "About Us",
  deliveryReturn: "Delivery & Returns",
  siteMap: "Site Map",
  ContactUs: "Contact Us",
  helpCenter: "Help Center",
  faq: "FAQ's",
  termsCondition: "Terms & Conditions",
  privacyPolciy: "Privacy Policy",
  usefulText: "Useful Links",
  quickLinkText: "Quick Links",
  dummyUserName: "ABCDEF",
  signup: "Sign Up",
  login: "Log In",
  getStarted: "Register your self with us and make your shopping easy",
  loginText: "Get started and discover the best offers around you",
  passwordSuggestionMsg:
    "Enter a passsword with alphabets A-z,numbers 0-9 and a symbol",
  registartionPageCheckboxText:
    "Check this box if you want to receive promotions, offers and newsletters",
  skipAsGuest: "Skip & Continue as Guest",
  signupWithText: "By Signing Up you agree with our",
  TermsAndConditions: "Terms & Conditions  & Privacy Policy",
  forgotPwdText: "Forgot Password?",
  phoneLoginText :"Login",
  registerPhoneNumberText: "Phone Number (+1)",
  registerName: "Full Name",
  registerEmail: "Email",
  registerPassword: "Password",
  otpVerfiy: "OTP Verification",
  otpText: "OTP has been sent to your email",
  phoneNumberOtpText: "OTP has been sent to your phone number",
  otp: "OTP",
  otpBtn: "OTP Verify",
  resendOTP: "Resend OTP",
  storeAddress: "Store Address",
  forgotPwdTagLine:
    "Enter your registered Email or Phone Number and we will send you OTP to reset your password",
  phoneLoginTagLine:"Enter your registered Phone Number and we’ll sent you OTP to login",
  createNewPassword: "Create New Password",
  invalidEmail: "Invalid email.",
  invalidPhoneNumber: "Invalid phone number.",
  emailMustBeValid: "Email must be a valid email",
  phoneNumberMustBeValid: "Please enter valid phone number with country code",
  pleaseEnterOTP: "Please Enter OTP",
  pleaseEnter5DigitOTP: "Please enter 4 digit OTP",
  pleaseEnter5DigitsOTP: "Please enter 5 digit OTP",
  invalidOTP: "OTP is Invalid",
  resetPwd: "Reset Password",
  newPwd: "New Password",
  confirmPwd: "Confirm Password",
  profile: "Profile",
  home: "Home",
  logout: "Logout",
  logoutTagLine: "Are you sure you want to logout from SS Restaurant Web ?",
  cancelBtn: "Cancel",
  logouBtn: "Logout",
  changePwdBtn: "Change Password",
  editProfileBtn: "Edit Profile",
  dummyRewardText: "Reward Points",
  viewTransactionHistoryBtn: "View Transaction History",
  rewardTitle: "Reward Points",
  historyTitle: "Transaction History",
  id: "ID",
  points: "Points",
  comments: "Comments",
  created: "Created",
  expires: "Expires",
  phoneNo: "Phone No",
  removePicBtn: "Remove Picture",
  saveProfileBtn: "Save Profile",
  updatePwdTitle: "Password changed successfully !",
  updatePwdTagLine:
    "You can now go back and continue browsing products  Enjoy Shopping !",
  updatePwdBtn: "Go to Profile",
  currentPwdLabel: "Enter current password",
  newPwdLabel: "Enter new password",
  confirmPwdLabel: "Re-enter new password",
  browseProductBtn: "Browse Products",
  noWishlistTagLine: "Explore more and shortlist some items",
  noOrderTagLine: "You haven’t order any items, Browse items and order it",
  noOrderTitle: "No Order",
  noWishlistTitle: "No Favorites",
  customize: "Customize",
  customized: "Customized*",
  removeWishlistTag: "The Item has been removed from the wishlist.",
  removeWishlistTitle: "Wishlist Item",
  okay: "Okay",
  orderNumber: "Order Number :",
  orderDate: "Ordered on ",
  quantity: "Quantity",
  writeReview: "Write a Review",
  rateTheOrder: "Rate the Order",
  orderRating: "Order Ratings",
  submitReview: "Submit Review",
  myOrders: "My Orders",
  orderDetails: "Order Details",
  orderStatus: "Order Status",
  shipAddress: "Shipping Address",

  userAddress: "Flat/Apartment/House number",
  userAddressLine1: "Address Line 1",
  userAddressLine2: "Address Line 2",
  city: "City",
  state: "State",
  country: "Country",
  pincode: "Pincode",

  trackingID: "Tracking ID:",
  talkUs: "Talk to Us",
  specailInstruction: "Special Instruction",
  arrivingIn: "Arriving in",
  someThingWent: "Something Went Wrong !!!",
  talkUsTagModalLine: "You can connect with us via",
  ContactUsTag: "Contact@website.com",
  phoneTalk: "+1 123 456 7895",
  currenySymbol: "£",
  noInstructionMsg: "Add here if any",
  yesDelete: "Yes, Delete",
  removeProduct: "Remove Cart Product",
  removeProductTagline: "The Item has been removed from the Cart.",
  productRating: "Product Ratings",
  menu: "Menu",
  veg: "VEG",
  nonVeg: "NON-VEG",
  myFavorites: "Favorites",
  showingResultFor: "Showing Result for",
  clearFilter: "Clear Filters",
  noResult: "No Products Found ",
  noResult2: "Search with different products",
  loadMore: "Load More",
  scrollToTop: "Scroll Up",
  itemTotal: "Item Total",
  taxes: "Taxes",
  addItem: "Add Item",
  productRatings: "Product Ratings",
  yourCart: "Your order",
  amount: "Amount",
  subTotal: "Sub Total",
  deliveryCharges: "Delivery Charges",
  deliveryDiscount: "Delivery Discount",
  discount: "Discount",
  applyCoupons: "Apply Coupons",
  appliedCoupon: "Applied Coupon Code",
  applyRewards: "Apply Rewards",
  appliedReward: "Applied Reward Points",
  viewAvailableCoupons: "View Available Coupons",
  noCouponsAvailable: "No Coupons Available",
  noReview: "No Any Review",
  noReviewSubtitle: "Sorry, There is no any reviews given by users.",
  notAvailable: "Not Available",
  review: "Reviews",
  goToHome: "Go to Home",
  customizeAttributes: "Customize Attributes :",
  youCannotReview: "You Cannot Review",
  errorOccured: "Error Occured",
  ok: "ok"
};

export default content;
