//@ts-ignore
import React from 'react';
import { withRouter } from "react-router-dom";
import { Container, Col, Row } from 'reactstrap';
import { AiOutlineYoutube } from 'react-icons/ai';
//images
import { AppStoreImg, facebookIcon, googleIcon, googlePlayStoreImg, instaIcon, logoImg, promotionsImg, twitterIcon, youtubeIcon } from './assets';
//css 
import './css/footerStyle.css';
//controller
import FooterController, { Props } from './FooterController.web';
// @ts-ignore
import isEmpty from "lodash/isEmpty";
// @ts-ignore
import includes from "lodash/includes";
//@ts-ignore
import capitalize from "lodash/capitalize";
//content file
//@ts-ignore
import content from '../../../studio-store-restaurant-theme/src/AppWebContent';
//lodaer
import FooterLoader from './FooterLoader.web';


class Footer extends FooterController {
    render() {
        // console.log(this.state.usefulLinks, "ReactVersion", React.version)
        // if (this.state.loading) {
        //     // @ts-ignore
        //     return <FooterLoader title="Loading ..." />
        // }
        return (
            <>
                <footer style={{ borderTop: '1px solid rgb(223, 223, 223)', background: "rgb(248,249,250)", }} className='mt-100'>
                    <div className='footercontainer yt-main-footer  dashboard-carousel-section'>
                        <>

                            <Row className='mt-5'>
                                <Col md={3} sm={12} xs={12} className="text-center">
                                    <div className="yt-footer-widget-title">
                                        {content.ContactUs}
                                    </div>
                                    <div className="d-flex justify-content-center" onClick={() => {
                                        this.props.history.push('/contact-us')
                                        // @ts-ignore
                                        window.scrollTo(0, 0)
                                    }}>
                                        <div className=" yt-text-about">
                                            Send a message
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center" onClick={() => {
                                        this.props.history.push('/contact-us')
                                        // @ts-ignore
                                        window.scrollTo(0, 0)
                                    }}>
                                        <div className=" yt-text-about">
                                            +{JSON.parse(localStorage.getItem('country_info') || '{}')?.country_code} {this.state.contactInfo?.callUs}
                                        </div>
                                    </div>

                                </Col>

                                <Col md={3} sm={12} xs={12} className="text-center">
                                    <div className="yt-footer-widget-title">
                                        {content.aboutUs}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className=" yt-text-about" onClick={() => {
                                            this.props.history.push('/review')
                                            // @ts-ignore
                                            window.scrollTo(0, 0)
                                        }}>
                                            Customer reviews
                                        </div>
                                    </div>
                                </Col>

                                <Col md={3} sm={12} xs={12} className="text-center">
                                    <div className="yt-footer-widget-title">
                                        Social
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className="social_all">
                                            {
                                                this.state.isShowFB && (
                                                    <>
                                                        {/* @ts-ignore */}
                                                        <a href={this.state.isShowFB ? this.state.isShowFB : "https:www.facebook.com/"} target="_blank">
                                                            <img
                                                                src={facebookIcon}
                                                                className="social_logos w3-ripple"
                                                                alt="social"
                                                            />
                                                        </a>{' '}
                                                    </>
                                                )
                                            }
                                            {this.state.isShowGoogle && (
                                                <>
                                                    {/* @ts-ignore */}
                                                    <a href={this.state.isShowGoogle ? this.state.isShowGoogle : "https:www.google.com/"} target="_blank">
                                                        <img
                                                            src={googleIcon}
                                                            className="social_logos w3-ripple"
                                                            alt="social"
                                                        />
                                                    </a>{' '}
                                                </>
                                            )}
                                            {this.state.isShowInsta && (
                                                <>
                                                    {/* @ts-ignore */}
                                                    <a href={this.state.isShowInsta ? this.state.isShowInsta : "https:www.instagram.com/"} target="_blank">
                                                        <img
                                                            src={instaIcon}
                                                            className="social_logos w3-ripple"
                                                            alt="social"
                                                        />
                                                    </a>{' '}
                                                </>
                                            )}

                                            {this.state.isShowTwitter && (
                                                <>
                                                    {/* @ts-ignore */}
                                                    <a href={this.state.isShowTwitter ? this.state.isShowTwitter : "https:twitter.com/"} target="_blank">
                                                        <img
                                                            src={twitterIcon}
                                                            className="social_logos w3-ripple"
                                                            alt="social"
                                                        />
                                                    </a>{' '}
                                                </>
                                            )}
                                            {this.state.isShowYoutube && (
                                                <>
                                                    {/* @ts-ignore */}
                                                    <a href={this.state.isShowYoutube ? this.state.isShowYoutube : "https:youtube.com/"} target="_blank">
                                                        <AiOutlineYoutube className="youtube-style" />
                                                        {/* <img
                                                        src={youtubeIcon}
                                                        className="social_logos w3-ripple"
                                                        alt="social"
                                                    /> */}
                                                    </a>{' '}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} sm={12} xs={12} className="text-center">
                                    <div className="yt-footer-widget-title">
                                        Download App
                                    </div>
                                    <div className='download-app-banner'>
                                        <img className="p-image img-fluid" onClick={() => window.open('https://play.google.com/store', "_blank")} alt="android" src={googlePlayStoreImg} />
                                        <img className="p-image img-fluid mt-2" onClick={() => window.open('https://www.apple.com/in/app-store/', "_blank")} alt="ios" src={AppStoreImg} />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </div>
                    <div className="bottombar">
                        <>
                            <p className="m-0 yt-copyright-text">
                                {/* @ts-ignore */}
                                {this.state.theamData?.footerContent?.copyright ? this.state.theamData?.footerContent?.copyright : content.copyRightText}
                            </p>
                        </>
                    </div>
                </footer >
            </>
        )
    }
};

//@ts-ignore
export default withRouter(React.memo(Footer));
